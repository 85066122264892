<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">TPR хязгаарлалтын тохиргоо</h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="createTpr"
          >Нэмэх</el-button
        >
        <router-link to="/tpr-config-history" class="ml10">
          <el-button type="info" size="mini">Хязгаарласан түүх харах</el-button>
        </router-link>
      </div>
    </div>
    <div class="panel">
      <el-row type="flex" justify="middle">
        <el-date-picker
          size="mini"
          v-model="filterDateTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="Эхлэх өдөр, цаг"
          end-placeholder="Дуусах өдөр, цаг"
          :default-time="['08:00:00']"
        >
        </el-date-picker>
        <el-input
          size="mini"
          class="ml5"
          prefix-icon="el-icon-search"
          v-model="minTpr"
          style="width: 100px"
          placeholder="TPR min"
        ></el-input>
        <el-input
          size="mini"
          class="ml5"
          prefix-icon="el-icon-search"
          v-model="maxTpr"
          style="width: 100px"
          placeholder="TPR max"
        >
        </el-input>
        <el-button
          type="primary"
          size="mini"
          class="ml10"
          @click="getTprReport"
        >
          <i class="el-icon-search"></i> Хайх</el-button
        >
      </el-row>
      <el-table
        size="mini"
        border
        class="mt10"
        :data="filterData"
        v-if="filterData.length > 0"
      >
        <el-table-column
          label="Давхардсан тоогоор"
          prop="repeatedCount"
        ></el-table-column>
        <el-table-column
          label="Давхардаагүй тоогоор"
          prop="notRepeatedCount"
        ></el-table-column>
        <el-table-column
          label="Нэвтрээгүй байсан"
          prop="unLogin"
        ></el-table-column>
        <el-table-column
          label="TPR хамгийн их утга"
          prop="maxTPR"
        ></el-table-column>
        <el-table-column label="TPR дундаж">
          <template slot-scope="scope">
            <span>{{ scope.row.avgTPR.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Хамгийн ачаалалттай цаг"
          prop="time"
        ></el-table-column>
        <el-table-column align="center">
          <template>
            <el-button
              type="primary"
              plain
              size="mini"
              @click="visibleDialog = true"
              >Газрын зураг харах</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-tabs type="border-card" class="mt10">
      <el-tab-pane label="Бүгд">
        <el-row :gutter="0">
          <el-col :span="24" :offset="0">
            <div class="payments-container">
              <div align="right">
                <el-input
                  style="width: 200px"
                  prefix-icon="el-icon-search"
                  v-model="search"
                  size="mini"
                  placeholder="Хайх үйлчилгээний нэр"
                  @change="inputSearch"
                />
              </div>
              <el-table :data="tprData" size="mini">
                <el-table-column label="Нэр" width="50px">
                  <template slot-scope="scope">
                    <span>H{{ scope.row.hexa_name }}</span></template
                  ></el-table-column
                >
                <el-table-column
                  prop="tpr_limit"
                  label="TPR хязгаар"
                  width="110px"
                  align="center"
                ></el-table-column>
                <el-table-column prop="status" label="Төлөв" width="100px">
                  <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.status === true"
                      >Идэвхтэй</el-tag
                    >
                    <el-tag v-else size="mini" type="info">Идэвхгүй</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="Өдөр">
                  <template slot-scope="scope">
                    <el-tag
                      size="mini"
                      type="warning"
                      v-for="(day, indexDay) in scope.row.weeking_days"
                      :key="indexDay"
                      >{{ day }}</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="desc" label="Тайлбар"></el-table-column>
                <el-table-column
                  prop="start_date"
                  label="Эхлэх огноо"
                ></el-table-column>
                <el-table-column
                  prop="end_date"
                  label="Дуусах огноо"
                ></el-table-column>
                <el-table-column
                  prop="start_time"
                  label="Эхлэх цаг"
                ></el-table-column>
                <el-table-column
                  prop="end_time"
                  label="Дуусах цаг"
                ></el-table-column>
                <el-table-column label="Үйлдэл" width="200px">
                  <template slot-scope="scope">
                    <el-button
                      v-if="!scope.row.is_default"
                      type="primary"
                      size="mini"
                      icon="el-icon-edit"
                      @click="handleClickPage(scope.row.id)"
                      round
                    ></el-button>
                    <el-button
                      v-if="!scope.row.is_default"
                      type="danger"
                      size="mini"
                      icon="el-icon-delete"
                      @click="deleteTpr(scope.row.id)"
                      round
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                class="mt10 text-right"
                background
                @size-change="sizeChangeHandler"
                layout="total, sizes, prev, pager, next"
                :total="pageCount"
                :page-size="size"
                :page-sizes="[5, 10, 20, 50, 100]"
                @current-change="setPage"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane> </el-tabs
    ><el-dialog :visible.sync="visibleDialog" width="50%" center>
      <vue-google-heatmap
        :points="points"
        :width="'100%'"
        :height="500"
        :lat="47.9186349"
        :lng="106.9164829"
        :initial-zoom="12"
      />
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import dayjs from "dayjs";
export default {
  name: "tprConfig",
  mounted() {
    this.getAllTpr();
    this.getTprReport();
  },
  data() {
    return {
      visibleDialog: false,
      minTpr: "",
      maxTpr: "",
      filterDateTime: [
        dayjs().format("YYYY-MM-DD 08:00:00"),
        dayjs().format("YYYY-MM-DD HH:mm:ss")
      ],
      filterData: [],
      tprData: [],
      tprEditData: null,
      dialogVisible: false,
      search: "",
      size: 20,
      from: 0,
      pageCount: 0,
      status: "",
      type: false,
      points: []
    };
  },
  watch: {
    search() {
      if (!this.search) {
        setTimeout(() => {
          this.getAllTpr();
        }, 500);
      }
    }
  },
  methods: {
    handleClickPage(id) {
      this.$router.push({
        path: "tpr-config-add",
        query: {
          id: id
        }
      });
    },

    sizeChangeHandler(size) {
      this.size = size;
      this.getAllTpr();
    },

    createTpr() {
      this.$router.push({
        path: "/tpr-config-add"
      });
    },

    setPage(page) {
      this.from = (page - 1) * this.size;
      this.getAllTpr();
    },

    closeFunction() {
      this.tprEditData = null;
      this.dialogVisible = false;
    },

    inputSearch(text) {
      if (text.length > 0) {
        this.getAllTpr();
      }
    },

    getTprReport() {
      this.filterData = [];
      this.openFullScreenLoader(true);
      service
        .getTprReport(
          this.minTpr ? parseFloat(this.minTpr) : "",
          this.maxTpr ? parseFloat(this.maxTpr) : "",
          this.filterDateTime ? this.filterDateTime[0] : "",
          this.filterDateTime ? this.filterDateTime[1] : ""
        )
        .then(response => {
          if (response.data.status === "success") {
            this.filterData.push(response.data.counts);
            this.points = response.data.data;
            this.openFullScreenLoader(false);
          } else {
            this.openFullScreenLoader(false);
          }
        })
        .catch(() => {
          this.openFullScreenLoader(false);
          this.$message({
            type: "info",
            message: "Хүсэлт ажмилтгүй."
          });
        });
    },

    getAllTpr() {
      this.openFullScreenLoader(true);
      service
        .getAllTpr(this.size, this.from, this.search, this.status)
        .then(response => {
          if (response.data.status === "success") {
            this.tprData = response.data.data;
            this.pageCount = response.data.total;
            this.openFullScreenLoader(false);
          } else {
            this.openFullScreenLoader(false);
          }
        })
        .catch(() => {
          this.openFullScreenLoader(false);
          this.$message({
            type: "info",
            message: "Хүсэлт ажилтгүй."
          });
        });
    },

    deleteTpr(id) {
      this.openFullScreenLoader(true);
      this.$confirm("Энэ TPR жагсаалт устгахдаа итгэлтэй байна уу?", "Анхаар", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteTpr(id).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: response.data.message,
                type: "success"
              });
              this.openFullScreenLoader(false);
              this.getAllTpr();
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message: response.data.message,
                type: "warning"
              });
              this.openFullScreenLoader(false);
            }
          });
        })
        .catch(() => {
          this.openFullScreenLoader(false);
          this.$message({
            type: "info",
            message: "Устгахад алдаа гарлаа."
          });
        });
    },

    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    }
  }
};
</script>

<style></style>
